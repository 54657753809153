/* stylelint-disable no-descending-specificity */
.productModalBody {
	padding-inline: min(var(--page-inset), var(--space-sm));
	display: flex;
	flex-direction: column;

	@media (--md) {
		padding-inline: min(var(--page-inset), 25px);
	}

	* {
		margin-top: 0 !important;
	}
}

.header.header {
	padding-inline: 0;
}

.modalHeader {
	font-size: 20px;
	font-weight: 600;

	@media (--md) {
		font-size: 24px;
	}
}

.productModalCloseIcon {
	cursor: pointer;
}

.sizes {
	--gap: 12px;

	padding-block: 30px 60px;
	display: flex;
	flex-direction: column;
	gap: var(--gap);

	@media (--md) {
		--gap: 20px;

		padding-block: 50px 72px;
	}
}

.measurements {
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tabs {
	gap: 14px;

	@media (--md) {
		gap: 11px;
	}
}

.tableWrapper {
	max-width: 100%;
	overflow: auto hidden;
	scrollbar-width: none;
	border: 1px solid var(--primary-dark-blue-01);
	border-radius: 8px;

	& .table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		border-radius: 8px;

		& tbody th,
		& thead th:first-child {
			position: sticky;
			left: 0;
			background: var(--primary-white);
			z-index: var(--z-above);
		}

		& th,
		td {
			min-width: 100px;
			min-height: 41px;
			white-space: nowrap;
			padding-block: 12px;
			text-align: center;
			border-right: 1px solid var(--primary-dark-blue-01);
			border-bottom: 1px solid var(--primary-dark-blue-01);

			&:last-child {
				border-right: none;
			}
		}

		& tr:last-child td {
			border-bottom: none;
		}

		.selected {
			color: var(--primary-white);
			background-color: var(--secondary-blue);
			border-bottom: 1px solid rgb(255 255 255 / 30%);
		}

		.disabled {
			color: var(--primary-dark-blue-02);
		}

		.hr {
			width: var(--space-lg);
			margin: auto;
			border-top: 1px solid var(--primary-dark-blue-02);
		}

		.left {
			text-align: left;
			padding-inline: var(--space-lg) var(--space-md);
			display: flex;
		}

		.text {
			font-size: 12px;
			font-weight: 500;
		}
	}
}

.scrollBar {
	display: none;
}

.content {
	padding-top: var(--space-sm);

	& h3 {
		margin-bottom: var(--space-2xs);

		@media (--md) {
			margin-bottom: var(--space-xs);
		}
	}

	& p {
		@extend %typo-body-2;
		margin-bottom: var(--space-sm);

		@media (--md) {
			margin-bottom: var(--space-md);
		}
	}
}

.headerMargin {
	& h3 {
		padding-top: var(--space-lg);
	}
}
