:where(.header) {
	background-color: var(--primary-greige);
	height: var(--header-min-height);
	position: sticky;
	top: 0;
	z-index: var(--z-sticky);
	transform: translateY(0);

	@media (--md) {
		transition: all 0.3s ease;
		will-change: transform, opacity;
	}

	&[data-direction="up"] {
		transform: translateY(0);
		opacity: 1;
		background-color: var(--primary-white);
		color: var(--color-foreground);
	}

	&[data-direction="down"] {
		transform: translateY(-100%);
		opacity: 0;
	}

	&[data-state="top"] {
		background-color: var(--primary-greige);
		transform: translateY(0);
	}
}
