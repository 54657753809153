.root {
	padding: 8px;
	background: var(--primary-white);
	visibility: hidden;
	opacity: 0;
	transition: var(--transition-standard);
	transition-property: opacity, visibility;

	@media (hover: hover) {
		&:hover {
			& svg {
				fill: black;
			}
		}
	}
}

.forceMount {
	opacity: 1;
	visibility: visible;
}
