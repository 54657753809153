.root.root {
	--category-btn-padding: 0px 16px 0px 10px;
	--category-btn-gap: 7px;
	--category-image-height: 25px;
	--category-image-width: 20px;
	--category-btn-height: 40px;

	@media (--md) {
		--category-btn-padding: 0px 30px 0px 15px;
		--category-btn-gap: 10px;
		--category-image-height: 48px;
		--category-image-width: 38px;
		--category-btn-height: 64px;
	}

	height: var(--category-btn-height);
	padding: var(--category-btn-padding);
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid var(--primary-dark-blue-005);

	& svg {
		margin-left: auto;
	}

	&:not(.bypassColor) {
		background-color: var(--primary-dark-blue-005);
		color: var(--primary-dark-blue);
	}

	& .main {
		display: flex;
		align-items: center;
		gap: var(--category-btn-gap);
	}

	& .categoryImage {
		width: var(--category-image-width);
		height: var(--category-image-height);
		position: relative;
		display: flex;
		align-items: center;

		& img {
			object-fit: contain;
		}
	}

	& .text {
		@extend %typo-utility-3;

		text-align: center;
		font-weight: 500;

		@media (--md) {
			@extend %typo-utility-1;
		}
	}
}

.evenPadding.evenPadding {
	padding: 15px;

	@media (--md) {
		padding-inline: 30px;
	}
}
