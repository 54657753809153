.root {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.fieldset {
	all: unset;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.shippingOption {
	--border-color: var(--primary-dark-blue-01);

	border: 1px solid var(--border-color);
	border-radius: 8px;
	padding: 20px;
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-areas:
		"radioButton optionHeader price"
		"optionSubHeader optionSubHeader optionSubHeader"
		"locations locations locations";
	gap: 5px;
	cursor: pointer;

	@media (--md) {
		gap: 10px;
		grid-template-areas:
			"radioButton optionHeader price"
			"radioButton optionSubHeader price"
			"radioButton locations locations";
	}

	@media (hover: hover) {
		&:hover {
			--border-color: var(--primary-dark-blue-02);
		}
	}

	&.selected {
		--border-color: var(--primary-dark-blue);
	}
}

.radioButton {
	width: 24px;
	height: 24px;
	padding: 3px;
	grid-area: radioButton;
	accent-color: var(--primary-dark-blue);
}

.optionHeaderLogo {
	--size: var(--space-md);

	width: var(--size);
	height: var(--size);
}

.optionHeaderText {
	font-weight: 600;
	margin: auto 0;
	font-size: 11px;

	@media (--md) {
		font-size: 15px;
	}
}

.optionHeader {
	grid-area: optionHeader;
	display: flex;
	align-items: center;
	gap: var(--space-2xs);
}

.optionSubHeader {
	grid-area: optionSubHeader;
	font-size: 13px;
}

.locations {
	grid-area: locations;
	width: 100%;

	& select {
		max-width: 100%;
		margin-top: 1px;

		@media (--md) {
			max-width: 310px;
			margin: 3px 0 3px -6px;
		}
	}
}

.price {
	font-weight: 600;
	grid-area: price;
	margin: auto 0 auto auto;
	font-size: 11px;

	@media (--md) {
		font-size: 21px;
	}
}
