.root {
	border-radius: 4px;
	border: 1px solid var(--primary-dark-blue-01);
	overflow: hidden;

	& .item {
		--background: var(--primary-white);
		--padding: var(--space-sm);

		background-color: var(--background);
		padding: var(--padding);
		transition: var(--transition-colors);

		@media (--md) {
			--padding: 17.5px;
		}

		&[open=] {
			--background: var(--secondary-beige);
		}
	}

	& .trigger {
		height: auto;
	}

	& .content {
		margin-top: var(--space-xs);
		padding: 0;

		@media (--md) {
			margin-top: var(--space-sm);
		}
	}
}
