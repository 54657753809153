.mobileMenu {
	max-width: 100%;
	padding-inline: var(--page-inset);
	display: flex;
	flex-direction: column;
	overflow: hidden;

	& a {
		text-decoration: none;
	}

	& .menuHeader {
		width: 100%;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	& .menuContainer {
		width: 100%;
		height: 100%;
		flex-shrink: 0;
		flex-grow: 1;
		position: relative;
		overflow: visible;
	}
}

.mobileLinkRoot {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.activeMenu {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: none;
	flex-direction: column;
	background-color: var(--primary-white);
	z-index: 1;

	&.active {
		display: flex;
	}
}

.accordionItem {
	border-top: 1px solid var(--primary-greige);
	padding: 15px 0;

	&:first-child {
		border: none;
	}

	& .accordionTrigger {
		& .chevron {
			transition: rotate 0.2s ease;
		}
	}

	&[open] {
		& .accordionTrigger .chevron {
			rotate: 180deg;
		}
	}
}

.accordionContent {
	margin-top: 15px;
	padding: 0;
	display: flex;
	flex-direction: column;
}

.categoryButton {
	width: 100%;
	margin-bottom: 5px;
}

.contentStory {
	margin-top: 30px;
	margin-left: 0;
	margin-right: auto;
	grid-area: story;

	& .contentStoryImage {
		width: 100%;
		aspect-ratio: 16 / 9;
		position: relative;

		& img {
			border-radius: var(--border-radius-sm);
			object-fit: cover;
		}
	}

	& h4 {
		margin-top: 19px;
	}

	@media (--md) {
		margin-left: auto;
		margin-right: 0;
	}
}

.storiesRoot {
	max-width: 100%;

	& .storiesWrapper {
		display: flex;
		gap: 2px;

		& .mobileStory {
			flex: 1 0 100%;

			@media (--md) {
				flex: 1 0 60%;
			}
		}
	}
}

.actionLinks {
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.actionLink {
	display: flex;
	align-items: center;
	gap: 10px;
}
