.root {
	--padding-inline: var(--page-inset);
	--columns: 2;

	height: 32px;
	position: relative;
	padding-inline: var(--padding-inline);
	display: grid;
	align-items: center;
	background: var(--secondary-blue);
	color: var(--primary-white);
	grid-template-columns: repeat(var(--columns), 1fr);
	z-index: 3;

	@media (--md) {
		--columns: 3;
	}

	&.light {
		background-color: var(--primary-greige);
		color: var(--primary-dark-blue);
	}

	.text {
		@extend %typo-utility-6;

		@media (--md) {
			@extend %typo-utility-5;
		}
	}

	.left {
		margin-left: 0;
		margin-right: auto;
	}

	.middle {
		--alignment: flex-end;

		width: 100%;
		display: flex;
		justify-content: var(--alignment);

		@media (--md) {
			--alignment: center;
		}
	}

	.right {
		width: 100%;
		margin-left: auto;
		margin-right: 0;
		display: none;
		gap: 25px;

		@media (--md) {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	& .link {
		color: inherit;
		text-decoration: none;
	}
}

.tags {
	position: relative;

	@extend %typo-utility-6;

	@media (--md) {
		@extend %typo-utility-5;
	}
}

.tag {
	width: max-content;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transform: translateY(-50%);
}

@keyframes animation {
	0%,
	100% {
		opacity: 0;
	}

	5%,
	20% {
		opacity: 1;
	}

	25%,
	95% {
		opacity: 0;
	}
}
