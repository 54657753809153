.root {
	margin-bottom: 13px;
	padding-inline: var(--page-inset);
	padding-top: 8px;
	padding-bottom: 9px;
	display: flex;
	align-items: center;
	line-height: 15px;

	a {
		text-decoration: none;
	}

	& button {
		max-height: 15px;
		margin: 0;
		padding: 0;
		font: inherit;
		border: none;
		background-color: transparent;
	}

	& .breadcrumb {
		text-transform: capitalize;
		font-weight: 500;
		color: var(--primary-dark-blue-04);

		&.dark {
			color: var(--primary-dark-blue);
		}
	}
}
