.root {
	border: unset;
	width: 100%;
	height: 50px;
	animation: skeleton-loading 1.5s infinite alternate;
	border-radius: var(--border-radius-sm);

	& > * {
		visibility: hidden;
	}
}

@keyframes skeleton-loading {
	0% {
		background-color: var(--primary-dark-blue-01);
	}

	100% {
		background-color: var(--primary-dark-blue-02);
	}
}
