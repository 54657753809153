:where(.root) {
	--icon-button-size: 32px;

	width: var(--icon-button-size);
	min-width: var(--icon-button-size);
	height: var(--icon-button-size);
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.2s ease;
	border-radius: 50%;

	& svg {
		width: 24px;
		height: 24px;
	}

	&.medium {
		--icon-button-size: 32px;
	}

	&.large {
		--icon-button-size: 40px;
	}

	&.xLarge {
		--icon-button-size: 48px;
	}

	&.small {
		--icon-button-size: 24px;
	}

	&.transparent {
		background-color: transparent;

		@media (hover: hover) {
			&:hover {
				background-color: var(--primary-dark-blue-005);
			}
		}
	}

	@media (--md) {
		&.md-medium {
			--icon-button-size: 32px;
		}

		&.md-large {
			--icon-button-size: 40px;
		}

		&.md-xLarge {
			--icon-button-size: 48px;
		}

		&.md-small {
			--icon-button-size: 24px;
		}
	}
}
