.root {
	--checkout-width: 1564px;

	max-width: var(--checkout-width);
	margin: 0 auto;
	margin-bottom: 200px;
	padding: 0;
	display: grid;
	gap: 5px;
	grid-template-columns: repeat(1, 1fr);
	grid-template-areas:
		"cartItems"
		"discountForm"
		"form"
		"cartSummary";

	@media (--md) {
		padding: 0 var(--page-inset);
		gap: var(--gap);
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			"form cart"
			"form cart";
	}
}

.content {
	margin-top: 16px;
}

.summaryTitle {
	max-width: 90%;

	@media (--md) {
		max-width: 60%;
	}
}

.title {
	padding: 24px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;

	& .triggerText {
		--text-color: var(--primary-dark-blue-06);

		font-size: 17px;
		color: var(--text-color);

		&.active {
			--text-color: var(--primary-dark-blue);
		}

		@media (--md) {
			font-size: 21px;
		}
	}

	& .checkIcon {
		--icon-size: 24px;

		width: var(--icon-size);
		height: var(--icon-size);
		background-color: var(--primary-greige);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (--md) {
			--icon-size: 32px;
		}
	}
}

.form {
	margin-right: 0;
	grid-area: form;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	gap: var(--space-2xs);

	@media (--md) {
		margin-top: var(--space-lg);
		gap: var(--space-xs);
	}
}

.item {
	background-color: var(--primary-white);
	border-top: 1px solid var(--primary-greige);
	border-radius: 8px;
	padding: 20px;
	padding-bottom: 20px;

	&:first-child {
		border: none;
	}

	& .title {
		padding-top: 0;
	}

	@media (--md) {
		padding-block: 30px;
		padding-inline: 40px;
		padding-bottom: 30px;
	}

	&[data-state="closed"] {
		& .title {
			padding: 0;
		}
	}

	&[data-state="open"] {
		padding-top: 30px;

		@media (--md) {
			padding-top: 40px;
		}
	}
}

.cart {
	--cart-size: 575px;

	width: 100%;
	max-width: var(--cart-size);
	margin: 0 auto;
	display: contents;

	@media (--md) {
		display: block;
		grid-area: cart;
	}
}

.cartWrapper {
	position: sticky;
	top: 0;
	display: contents;

	@media (--md) {
		display: block;
	}
}

.cartItems {
	padding-inline: 20px;
	padding-bottom: 24px;
	grid-area: cartItems;
	background-color: var(--primary-white);

	@media (--md) {
		padding-inline: 0;
		background-color: transparent;
	}
}

.summaryHeader {
	margin-top: 30px;
	margin-bottom: 22px;

	@media (--md) {
		margin-top: 56px;
		margin-bottom: 24px;
	}
}

.cartSummary {
	max-height: fit-content;
	padding-inline: 15px;
	padding-bottom: 24px;
	grid-area: cartSummary;
	background-color: var(--primary-white);

	@media (--md) {
		padding-inline: 0;
		background-color: transparent;
	}
}

.summaryItems {
	padding: 23px 0 20px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media (--md) {
		padding: 16px 0 25px;
	}
}

.summaryRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.borderBottom {
	border-bottom: 1px solid var(--primary-dark-blue-01);
}

.right {
	margin-left: auto;
	margin-right: 0;
}

.mobile {
	display: block;

	@media (--md) {
		display: none;
	}
}

.desktop {
	display: none;

	@media (--md) {
		display: block;
	}
}

.discountMobileForm {
	padding-inline: 20px;
}

.discountForm {
	grid-area: discountForm;
	background-color: var(--primary-white);
	text-align: center;
	padding-top: var(--space-lg);
	padding-bottom: var(--space-lg);
	border-bottom: 1px solid var(--primary-dark-blue-01);

	& summary {
		&::marker,
		&::-webkit-details-marker {
			display: none;
		}
	}

	& summary:first-of-type {
		list-style-type: none;
	}

	@media (--md) {
		padding-top: 0;
		background-color: inherit;
		text-align: left;
	}
}

.discount {
	margin-top: 20px;
	display: flex;
	align-items: center;
	gap: 15px;
}

.dicountInput {
	flex-grow: 1;
}

.discountButton {
	white-space: nowrap;
	flex-shrink: 0;
}

.discountText {
	margin-top: 0;
	cursor: pointer;

	@media (--md) {
		margin-top: 34px;
	}
}

.discountCode {
	align-items: start;
}
